import { myTripsEndpoints } from '@redux/features/myTrips/myTrips.api'
import type { UpcomingStoreOrdersType } from '@redux/features/myTrips/types/upcomingTrips'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

export default createAsyncThunk<
  void,
  {
    storeOrders: UpcomingStoreOrdersType
    userId: UserIdType
  },
  { state: RootState }
>('myTrips/getServiceOrdersList', async ({ storeOrders, userId }, { dispatch }) => {
  for (const storeOrder of Object.values(storeOrders || {})) {
    try {
      await dispatch(myTripsEndpoints.getUpcomingServiceOrders.initiate({ hash: storeOrder.hash, userId }))
    } catch (error) {
      console.error(error)
    }
  }
})
