import type { FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

type FeeDescriptionTypePartialType = { condition?: null | string; label?: null | string } | null

type Props = {
  cancellationFee?: FeeDescriptionTypePartialType
  description?: null | string
  modificationFee?: FeeDescriptionTypePartialType
}

const FareInfo: FC<Props> = ({ cancellationFee, description, modificationFee }) => {
  const { t } = useTranslation('Fare info')

  return (
    <>
      {cancellationFee?.condition && (
        <p>
          <strong>{cancellationFee.label || t('cancellation&refund')}: </strong>
          <span dangerouslySetInnerHTML={{ __html: cancellationFee.condition }} />
        </p>
      )}
      {modificationFee?.condition && (
        <p>
          <strong>{modificationFee.label || t('modification')}: </strong>
          <span dangerouslySetInnerHTML={{ __html: modificationFee.condition }} />
        </p>
      )}
      {description && <span dangerouslySetInnerHTML={{ __html: description }} />}
    </>
  )
}

export default memo(FareInfo)
