import type { FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { toggleBurgerMainMenu } from '@redux/features/modals/modals.slice'
import { useAppDispatch } from '@redux/hooks'

import { MobileMenuButtonStyled } from '@components/header/BurgerMenu/MobileMenuButton/styles'
import { UilBars } from '@iconscout/react-unicons'

const MobileMenuButton: FC = () => {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()

  const openMenu = () => dispatch(toggleBurgerMainMenu(true))

  return (
    <MobileMenuButtonStyled iconColor="primary" onClick={openMenu} type="link">
      <UilBars />
      <span>{t('menu')}</span>
    </MobileMenuButtonStyled>
  )
}

export default memo(MobileMenuButton)
