import Drawer from 'antd/es/drawer'
import type { DrawerProps } from 'antd/es/drawer'
import type { Placement } from 'rc-drawer/lib/Drawer'
import type { FC, ReactNode } from 'react'
import { useEffect, useId, useState } from 'react'
import { useTheme } from 'styled-components'

import type { SimpleModals } from '@redux/features/modals/constants/simpleModals'
import { selectIsOpenedDrawerModal } from '@redux/features/modals/modals.selectors'
import { useAppSelector } from '@redux/hooks'

import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'

export type DrawerModalProps = {
  children: ReactNode
  isFullScreen?: boolean
  isOpened?: boolean
  modalName?: SimpleModals
  placement?: Placement
} & DrawerProps

const DrawerModal: FC<DrawerModalProps> = ({
  children,
  isFullScreen,
  isOpened,
  modalName,
  placement = 'right',
  styles,
  width,
  ...props
}) => {
  const theme = useTheme()
  const isOpenedRedux = useAppSelector(selectIsOpenedDrawerModal(modalName))
  const open = typeof isOpened === 'boolean' ? isOpened : isOpenedRedux
  const [isFirstDrawer, setIsFirstDrawer] = useState(false)
  const isMobile = useMediaQuery(mediaQueries.mobile)
  const id = useId()

  useEffect(() => {
    void Promise.resolve().then(() => {
      setIsFirstDrawer(document.querySelectorAll('.ant-drawer-open .ant-drawer-content')[0]?.id === id)
    })
  }, [modalName, open])

  return (
    <Drawer
      styles={{
        body: {
          background: theme.colors.backgroundMain,
          display: 'flex',
          flexDirection: 'column',
          padding: '0',
          ...styles?.body,
        },
        content: styles?.content,
        mask: { background: isFirstDrawer ? '#000000BF' : 'none', ...styles?.mask },
        wrapper: {
          borderRadius: isMobile || isFullScreen ? 0 : theme.borderRadius.default,
          boxShadow: theme.shadows.big,
          margin: isMobile || isFullScreen ? '0' : '32px',
          overflow: 'hidden',
          width: width ? width : isMobile || isFullScreen ? '100%' : '390px',
          ...styles?.wrapper,
        },
      }}
      closable={false}
      destroyOnClose={false}
      id={id}
      open={open}
      placement={placement}
      style={{ background: 'none', borderRadius: 'inherit', ...styles?.content }}
      {...props}
    >
      {children}
    </Drawer>
  )
}

export default DrawerModal
