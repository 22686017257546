import type { InputRef } from 'antd/es'
import { useEffect } from 'react'

import { useLatest } from '@hooks/useLatest/useLatest'

type ActionType = 'click' | 'mousedown' | 'mouseup' | 'touchstart'

type Props = {
  actions?: ActionType[]
  elementsRefs?: (HTMLElement | null)[]
  exceptionsRefs?: (Element | HTMLElement | InputRef | null)[]
  handler: () => void
  isActive?: boolean
  selector?: string
}

export const useOutsideClick = ({
  actions = ['click', 'touchstart'],
  elementsRefs,
  exceptionsRefs,
  handler,
  isActive = true,
  selector,
}: Props) => {
  const latestHandler = useLatest(handler)

  useEffect(() => {
    if (!isActive || (!selector && !elementsRefs)) return

    const handleClick = (e: MouseEvent | TouchEvent) => {
      const elementsArray = (elementsRefs || []).concat(
        selector ? ([...document.querySelectorAll(selector)] as HTMLElement[]) : []
      )

      if (elementsArray.every(el => !el)) return

      const isExceptionClicked = exceptionsRefs?.some(
        exceptionRef => exceptionRef instanceof HTMLElement && exceptionRef.contains(e.target as Node)
      )

      if (isExceptionClicked) return

      if (
        !elementsArray.some(
          el => el instanceof HTMLElement && typeof el.contains === 'function' && el.contains(e.target as Node)
        )
      ) {
        handler()
      }
    }

    actions.forEach(action => {
      document.addEventListener(action, handleClick)
    })

    return () => {
      actions.forEach(action => {
        document.removeEventListener(action, handleClick)
      })
    }
  }, [elementsRefs, exceptionsRefs, latestHandler, isActive, actions, selector, handler])
}
