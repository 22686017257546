import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import type { IStation, IStationV3 } from '@Types/stations/station'
import useStationName from '@hooks/useStationName/useStationName'
import { ArrowIcon } from '@styles/arrowIcon/arrowIcon'
import { ellipsisText } from '@styles/typography/textStyles'
import Title from '@typography/Title'

type Props = {
  arrivalStation: IStation | IStationV3
  departureStation: IStation | IStationV3
}

const Route: FC<Props> = ({ arrivalStation, departureStation }) => {
  const departureStationName = useStationName(departureStation)
  const arrivalStationName = useStationName(arrivalStation)

  return (
    <Wrapper aria-label="route-stations">
      <Station level={6}>{departureStationName}</Station>
      <ArrowIcon />
      <Station level={6}>{arrivalStationName}</Station>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-flex;

  ${p => p.theme.mediaQueries.mobile} {
    padding: 0 16px;
  }
`

const Station = styled(Title)`
  ${ellipsisText};
`

export default memo(Route)
