import { useGetStationQuery } from '@redux/features/trainData/trainData.api'
import { skipToken } from '@reduxjs/toolkit/query/react'

import type { IStation, IStationV3 } from '@Types/stations/station'
import { getStationName } from '@utils/route/routeName'

const useStationName = (station?: IStation | IStationV3) => {
  const parentStationId =
    (typeof (station as IStation)?.parent_station === 'number' && ((station as IStation).parent_station as number)) ||
    (typeof (station as IStationV3)?.parentStation === 'number' && ((station as IStationV3).parentStation as number))

  const { data: parentStationFetched } = useGetStationQuery(parentStationId || skipToken)

  if (!station) return ''

  const parentStation =
    !parentStationId && (station as IStationV3)?.parentStation
      ? ((station as IStationV3)?.parentStation as IStationV3)
      : Array.isArray((station as IStation)?.parent_station)
      ? ((station as IStation).parent_station as [IStation])[0]
      : ((station as IStation)?.parent_station as IStation)

  const resultStation = parentStationId ? parentStationFetched : parentStation || station

  return resultStation ? getStationName(resultStation) : ''
}

export default useStationName
