import Link from 'next/link'
import type { FC } from 'react'
import { Fragment, memo } from 'react'
import styled, { css } from 'styled-components'

import { toggleBurgerMainMenu } from '@redux/features/modals/modals.slice'
import { myTripsEndpoints } from '@redux/features/myTrips/myTrips.api'
import { useAppDispatch } from '@redux/hooks'

import type { IMyTripsStoreOrder } from '@Types/myTrips/myTripsStoreOrder'
import Divider from '@base/Divider'
import Loader from '@base/loader/Loader'
import MyTripsServiceOrder from '@components/myTrips/myTripsList/myTripsStoreOrder/myTripsServiceOrder/MyTripsServiceOrder'
import { getRoutes } from '@constants/routes/routes'

type Props = {
  isInMobileMenu?: boolean
  storeOrder: IMyTripsStoreOrder
  userId: UserIdType
}

const MyTripsStoreOrder: FC<Props> = ({ isInMobileMenu, storeOrder, userId }) => {
  const dispatch = useAppDispatch()
  const { data: serviceOrders } = myTripsEndpoints.getUpcomingServiceOrders.useQueryState({
    hash: storeOrder.hash,
    userId,
  })

  const onClick = () => {
    dispatch(toggleBurgerMainMenu(false))
  }

  return (
    <Wrapper $isInMobileMenu={isInMobileMenu} href={getRoutes.order(storeOrder.hash)} onClick={onClick}>
      {serviceOrders ? (
        Object.values(serviceOrders).map((serviceOrder, index) => (
          <Fragment key={serviceOrder.id}>
            {isInMobileMenu && index > 0 && <Divider style={{ margin: '0 -24px', width: 'calc(100% + 48px)' }} />}
            <MyTripsServiceOrder serviceOrder={serviceOrder} userId={userId} />
          </Fragment>
        ))
      ) : (
        <Loader />
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Link)<{ $isInMobileMenu?: boolean }>`
  padding: 20px 24px;
  display: grid;
  grid-gap: 16px;

  &:not(:first-child) {
    background: ${p => p.theme.gradient.grayToWhite};
  }

  ${p =>
    p.$isInMobileMenu &&
    css`
      &,
      &:not(:first-child) {
        background: ${p => p.theme.colors.backgroundAdditional};
      }
      border-radius: ${p => p.theme.borderRadius.default};
    `};
`

export default memo(MyTripsStoreOrder)
