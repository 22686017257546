import Menu, { type MenuProps } from 'antd/es/menu'
import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import Title, { Title6 } from '@typography/Title'

type Props = {
  onClick?: (value: { key: string }) => void
  options: MenuProps['items']
  title: string
}

const Selector: FC<Props> = ({ onClick, options, title }) => {
  return (
    <Wrapper>
      <Title level={6}>{title}</Title>
      <Menu items={options} onClick={onClick} style={{ maxHeight: '180px', overflowY: 'auto' }} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 35px 24px 35px 12px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-gap: 8px;
  width: 235px;

  .ant-menu {
    background: ${p => p.theme.colors.backgroundMain};
  }

  &:not(:first-child) {
    border-inline-start: 1px solid ${p => p.theme.colors.fontSecondaryLight};
  }

  ${Title6} {
    padding-inline-start: 28px;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;

    &-track {
      background: ${p => p.theme.colors.backgroundBlueContainer};
    }
  }
`

export default memo(Selector)
