import styled from 'styled-components'

import { UilGlobe } from '@iconscout/react-unicons'

export const GlobeIcon = styled(UilGlobe)`
  display: flex;
  align-items: center;
  cursor: pointer;
  fill: ${p => p.theme.colors.icon};
`

export const RegionalMenuContainer = styled.span``
