import type { LangCodesTypes } from '@reduxCommonApp/types/siteInfo'

export const flagByLanguageId: Partial<Record<LangCodesTypes, string>> = {
  ar: 'sa',
  el: 'gr',
  en: 'us',
  ko: 'kr',
  nb: 'no',
  sv: 'se',
  'zh-hans': 'cn',
  'zh-hant': 'tw',
}
