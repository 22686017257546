import ConfigProvider from 'antd/es/config-provider'
import type { FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { useGetCurrenciesQuery } from '@reduxCommonApp/commonApp.api'

import CurrencyList from '@components/header/RegionalMenu/regionalMenuDropdown/currency/CurrencyList'
import LangLabel from '@components/header/RegionalMenu/regionalMenuDropdown/langLabel/LangLabel'
import Selector from '@components/header/RegionalMenu/regionalMenuDropdown/selector/Selector'
import useLangCode from '@hooks/useLangCode/useLangCode'
import useLanguageList from '@hooks/useLanguageList/useLanguageList'

const RegionalMenuDropdown: FC = () => {
  const { t } = useTranslation('Header')
  const currentLangCode = useLangCode()
  const theme = useTheme()

  const { data: currenciesList } = useGetCurrenciesQuery(true)
  const languagesList = useLanguageList()

  return (
    <Wrapper>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              activeBarBorderWidth: 0,
              itemHoverBg: theme?.colors.backgroundBlueContainer,
              padding: 24,
            },
          },
          token: {},
        }}
      >
        {languagesList && (
          <Selector
            options={Object.values(languagesList).map(({ id, name, prefix }) => ({
              disabled: currentLangCode === id,
              key: id,
              label: <LangLabel currentLangCode={currentLangCode} langId={id} prefix={prefix} title={name} />,
            }))}
            title={t('languages')}
          />
        )}
        {currenciesList && <CurrencyList currenciesList={currenciesList} />}
      </ConfigProvider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${p => p.theme.colors.backgroundMain};
  border-radius: ${p => p.theme.borderRadius.default};
  overflow: hidden;
  display: inline-flex;
  box-shadow: ${p => p.theme.shadows.modal};

  .ant-menu-vertical > .ant-menu-item {
    height: 34px;
    line-height: 34px;
    display: flex;
    align-items: center;
  }
`

export default memo(RegionalMenuDropdown)
