import { Popover } from 'antd/es'
import type { TooltipPlacement } from 'antd/es/tooltip'
import type { FC } from 'react'
import { memo, useEffect } from 'react'

import { selectUserCurrency } from '@redux/features/user/user.selectors'
import { useAppSelector } from '@redux/hooks'

import { headerId } from '@components/header/Header'
import RegionalMenuDropdown from '@components/header/RegionalMenu/regionalMenuDropdown/RegionalMenuDropdown'
import { GlobeIcon, RegionalMenuContainer } from '@components/header/RegionalMenu/styles'
import useToggle from '@hooks/useToggle/useToggle'

type Props = {
  placement?: TooltipPlacement
}

const RegionalMenu: FC<Props> = ({ placement = 'bottomRight' }) => {
  const { on: isOpenRegionalMenu, setValue: setIsOpenRegionalMenu, toggleOff: closeRegionalMenu } = useToggle()
  const currentCurrency = useAppSelector(selectUserCurrency)

  useEffect(() => closeRegionalMenu(), [currentCurrency])

  return (
    <Popover
      arrow={false}
      content={<RegionalMenuDropdown />}
      destroyTooltipOnHide={false}
      getPopupContainer={node => document.getElementById(headerId) || node}
      onOpenChange={setIsOpenRegionalMenu}
      open={isOpenRegionalMenu}
      overlayInnerStyle={{ background: 'none', boxShadow: 'none', padding: 0 }}
      placement={placement}
      trigger="click"
    >
      <RegionalMenuContainer>
        <GlobeIcon />
      </RegionalMenuContainer>
    </Popover>
  )
}

export default memo(RegionalMenu)
