import { useGetSiteInfoQuery } from '@reduxCommonApp/commonApp.api'
import type { LangCodesTypes } from '@reduxCommonApp/types/siteInfo'

import defaultParameters from '@constants/defaultParameters/defaultParameters'
import userSettingsStorage from '@storageServices/storageEntities/userSettings'

const useLangCode = () => {
  const { data: siteInfo } = useGetSiteInfoQuery(typeof window !== 'undefined' ? document.location.search : undefined)

  return (siteInfo?.langcode ||
    (typeof document !== 'undefined' && document.documentElement.getAttribute('lang')) ||
    userSettingsStorage.getValue()?.langcode ||
    defaultParameters.langcode) as LangCodesTypes
}

export default useLangCode
