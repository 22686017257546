import { Popover } from 'antd/es'
import type { TooltipPlacement } from 'antd/es/tooltip'
import type { FC, ReactNode } from 'react'
import { memo, useCallback, useEffect } from 'react'
import { useTheme } from 'styled-components'

import { useGetUpcomingStoreOrdersQuery } from '@redux/features/myTrips/myTrips.api'
import { skipToken } from '@reduxjs/toolkit/query/react'

import { MyTripsPopupHOCContainer } from '@components/myTrips/MyTripsPopup/styles'
import MyTripsPopup from '@components/myTrips/myTripsList/MyTripsList'
import TicketsBadgeHoc from '@hocs/tickets/TicketsBadgeHoc'
import useOpenAuthPopup from '@hooks/useOpenAuthPopup/useOpenAuthPopup'
import useToggle from '@hooks/useToggle/useToggle'
import { useUserId } from '@hooks/user/useUserId/useUserId'

type Props = {
  children: ReactNode //focusable element (button, a)
  placement?: TooltipPlacement
}

const MyTripsPopupHOC: FC<Props> = ({ children, placement = 'bottomRight' }) => {
  const theme = useTheme()
  const userId = useUserId()()
  const { isLoggedIn, openAuthPopup } = useOpenAuthPopup()
  const { on: trigger, toggleOff: setTriggerOff, toggleOn: setTriggerOn } = useToggle()
  const { on: isOpen, toggle: toggleIsOpen, toggleOn: setIsOpenOn } = useToggle()
  const { data: storeOrders, refetch } = useGetUpcomingStoreOrdersQuery(userId ?? skipToken)

  const openAuthPopupHandler = () => {
    setTriggerOn()
    openAuthPopup()
  }

  useEffect(() => {
    if (trigger && userId) {
      setIsOpenOn()
      setTriggerOff()
    }
  }, [trigger, userId, setIsOpenOn, setTriggerOff])

  const desktopOnOpenChange = useCallback(
    (value: boolean) => {
      value && void refetch()
      toggleIsOpen()
    },
    [refetch, toggleIsOpen]
  )

  if (!isLoggedIn) return <MyTripsPopupHOCContainer onClick={openAuthPopupHandler}>{children}</MyTripsPopupHOCContainer>

  return (
    <Popover
      align={{
        overflow: { adjustX: 0, adjustY: 0 },
        targetOffset: [0, placement.includes('top') ? 20 : -20],
      }}
      overlayInnerStyle={{
        backgroundColor: 'none',
        boxShadow: theme.shadows.modal,
        padding: 0,
        width: '390px',
      }}
      arrow={false}
      content={<MyTripsPopup storeOrders={storeOrders} userId={userId} />}
      destroyTooltipOnHide={false}
      onOpenChange={desktopOnOpenChange}
      open={isOpen}
      placement={placement}
      trigger="click"
    >
      <MyTripsPopupHOCContainer>
        <TicketsBadgeHoc>
          <>{children}</>
        </TicketsBadgeHoc>
      </MyTripsPopupHOCContainer>
    </Popover>
  )
}

export default memo(MyTripsPopupHOC)
