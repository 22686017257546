import type { FC } from 'react'
import { useEffect } from 'react'
import { memo } from 'react'
import styled, { css } from 'styled-components'

import { getServiceOrdersList } from '@redux/features/myTrips/thunks'
import type { UpcomingStoreOrdersType } from '@redux/features/myTrips/types/upcomingTrips'
import { useAppDispatch } from '@redux/hooks'

import Loader from '@base/loader/Loader'
import MyTripsStoreOrder from '@components/myTrips/myTripsList/myTripsStoreOrder/MyTripsStoreOrder'
import Caption from '@typography/Caption'

type Props = {
  isInMobileMenu?: boolean
  storeOrders?: UpcomingStoreOrdersType
  userId?: UserIdType
}

const MyTripsList: FC<Props> = ({ isInMobileMenu, storeOrders, userId }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (storeOrders && userId) void dispatch(getServiceOrdersList({ storeOrders, userId }))
  }, [storeOrders, userId, dispatch])

  if (!userId) return null

  return (
    <Wrapper $isInMobileMenu={isInMobileMenu}>
      {storeOrders ? (
        Object.values(storeOrders).length ? (
          Object.values(storeOrders).map(storeOrder => (
            <MyTripsStoreOrder
              isInMobileMenu={isInMobileMenu}
              key={storeOrder.hash}
              storeOrder={storeOrder}
              userId={userId}
            />
          ))
        ) : (
          <div style={{ padding: '20px' }}>
            <Caption>You have no orders</Caption>
          </div>
        )
      ) : (
        <Loader />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $isInMobileMenu?: boolean }>`
  border-radius: ${p => p.theme.borderRadius.default};
  box-shadow: ${p => p.theme.shadows.modal};
  overflow: hidden;
  max-height: calc(100vh - 400px);
  overflow-y: auto;

  ${p =>
    p.$isInMobileMenu &&
    css`
      max-height: initial;
      border-radius: 0;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      gap: 16px;
    `};
`

export default memo(MyTripsList)
