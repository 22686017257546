import type { LinkProps } from 'next/link'
import Link from 'next/link'
import type { FC, ReactElement } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

type Props = {
  children: ReactElement
  to?: LinkProps['href']
} & Omit<LinkProps, 'href'>

const GoHomeHoc: FC<Props> = ({ children, to, ...props }) => {
  return (
    <GoHomeHocStyled href={to || '/'} {...props} aria-label="go home link">
      {children}
    </GoHomeHocStyled>
  )
}

export const GoHomeHocStyled = styled(Link)`
  display: grid;
  place-content: center;
`

export default memo(GoHomeHoc)
