import styled from 'styled-components'

import { UilArrowRight } from '@iconscout/react-unicons'

export const ArrowIcon = styled(UilArrowRight)`
  fill: ${p => p.theme.colors.primary};
  margin: 0 16px;
  width: 24px;

  [dir='rtl'] & {
    transform: rotate(180deg);
  }
`
