import type { CSSProperties, FC } from 'react'

import { FlagStyled } from './styles'

export type FlagIconProps = {
  code: string
  height?: CSSProperties['height']
  onClick?: () => void
  styles?: CSSProperties
  width?: CSSProperties['width']
}

const FlagIcon: FC<FlagIconProps> = ({ code, height = '16px', onClick, styles, width = '30px' }) => {
  return <FlagStyled $code={code} height={height} key={code} onClick={onClick} styles={styles} width={width} />
}

export default FlagIcon
