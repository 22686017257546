import type { FC } from 'react'

import { UilSignout } from '@iconscout/react-unicons'

import { LogoutButtonStyled } from './styles'

type Props = {
  logOutHandler: () => void
}

const LogoutButton: FC<Props> = ({ logOutHandler }) => {
  return (
    <LogoutButtonStyled onClick={logOutHandler} type="link">
      <UilSignout />
    </LogoutButtonStyled>
  )
}

export default LogoutButton
