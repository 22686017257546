import type { CSSProperties, DOMAttributes, FC, ReactNode } from 'react'
import type { RuleSet } from 'styled-components'
import styled from 'styled-components'

import {
  textLargeItalicStyles,
  textLargeMediumStyles,
  textLargeRegularStyles,
  textMediumItalicStyles,
  textMediumMediumStyles,
  textMediumRegularStyles,
  textSmallRegularStyles,
} from '@styles/typography/text'

export type VariantType = 'italic' | 'medium' | 'regular'
export type SizeType = 'l' | 'm' | 's'

type Props = {
  children: ReactNode
  size?: SizeType
  style?: CSSProperties
  textAlign?: CSSProperties['textAlign']
  variant?: VariantType
} & DOMAttributes<HTMLElement>

export const Text: FC<Props> = ({
  children,
  size = 'm',
  style,
  textAlign = 'start',
  variant = 'regular',
  ...props
}) => {
  return (
    <StyledText $textAlign={textAlign} $variant={variant} size={size} style={style} {...props}>
      {children}
    </StyledText>
  )
}

export const StyledText = styled.span<{
  $textAlign: CSSProperties['textAlign']
  $variant: VariantType
  size: SizeType
}>`
  ${p => styles[p.size][p.$variant]};
  text-align: ${p => p.$textAlign};
`

const styles: Record<SizeType, Record<VariantType, RuleSet<object>>> = {
  l: {
    italic: textLargeItalicStyles, // Txt/Large 2
    medium: textLargeMediumStyles, // Txt/Large 3
    regular: textLargeRegularStyles, // Txt/Large 1
  },
  m: {
    italic: textMediumItalicStyles, // Txt/Medium 1
    medium: textMediumMediumStyles, // Txt/Medium 2
    regular: textMediumRegularStyles, // Txt/Medium 3
  },
  s: {
    italic: textSmallRegularStyles,
    medium: textSmallRegularStyles,
    regular: textSmallRegularStyles, //Txt/Small 1
  },
}

export default Text
