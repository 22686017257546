import type { FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { selectUserCurrency } from '@redux/features/user/user.selectors'
import { setUserCurrency } from '@redux/features/user/user.slice'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import type { CurrenciesList } from '@reduxCommonApp/types/commonAppState'

import CurrencyLabel from '@components/header/RegionalMenu/regionalMenuDropdown/currency/CurrencyLabel'
import Selector from '@components/header/RegionalMenu/regionalMenuDropdown/selector/Selector'

type Props = {
  currenciesList?: CurrenciesList
}

const CurrencyList: FC<Props> = ({ currenciesList }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('Header')
  const currentCurrency = useAppSelector(selectUserCurrency)

  const onChangeHandlerClick = ({ key }: { key: string }) => {
    dispatch(setUserCurrency(key))
  }

  return (
    currenciesList && (
      <Selector
        options={Object.values(currenciesList).map(currency => {
          const isCurrent = currency.currencyCode === currentCurrency

          return {
            disabled: isCurrent,
            key: currency.currencyCode,
            label: <CurrencyLabel isCurrent={isCurrent} {...currency} />,
          }
        })}
        onClick={onChangeHandlerClick}
        title={t('currency')}
      />
    )
  )
}

export default memo(CurrencyList)
