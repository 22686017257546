import type { SpinProps } from 'antd/es'
import { Spin } from 'antd/es'
import type { CSSProperties, FC } from 'react'
import { memo } from 'react'

type Props = {
  absoluteCenter?: boolean
} & SpinProps

const absoluteCenterStyles: CSSProperties = {
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
}

const Loader: FC<Props> = ({ absoluteCenter, style, ...props }) => {
  return <Spin style={absoluteCenter ? { ...absoluteCenterStyles, ...style } : style} {...props} />
}

export default memo(Loader)
