import dayjs from 'dayjs'
import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import dateFormats from '@constants/dates/dateFormats'
import { UilArrowRight } from '@iconscout/react-unicons'
import Text from '@typography/Text'

type Props = {
  arrivalDateTime: string
  departureDateTime: string
}

const Dates: FC<Props> = ({ arrivalDateTime, departureDateTime }) => {
  const arrivalDayJs = dayjs.parseZone(arrivalDateTime)
  const departureDayJs = dayjs.parseZone(departureDateTime)

  return (
    <Wrapper>
      <DepartureDate variant="medium">{departureDayJs.format(dateFormats['Sunday, 1 Jan 2023'])}</DepartureDate>
      <Times variant="medium">
        <time>{departureDayJs.format(dateFormats['01:01 AM'])}</time>
        <ArrowIcon />
        <time>{arrivalDayJs.format(dateFormats['01:01 AM'])}</time>
      </Times>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 16px;
  row-gap: 8px;
`

const DepartureDate = styled(Text)``

const Times = styled(Text)`
  display: inline-flex;
  gap: 8px;
`

const ArrowIcon = styled(UilArrowRight)`
  fill: ${p => p.theme.colors.primary};
  margin-inline: 8px;
  width: 24px;

  [dir='rtl'] & {
    transform: rotate(180deg);
  }
`

export default memo(Dates)
