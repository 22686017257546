import { useMemo } from 'react'

import { useGetLanguagesQuery } from '@redux/features/commonApp/commonApp.api'
import type { LanguagesList } from '@redux/features/commonApp/types/commonAppState'

const useLanguageList = () => {
  const { data: languagesList } = useGetLanguagesQuery()
  const filteredLanguagesList = useMemo(
    () =>
      Object.fromEntries(Object.entries(languagesList || []).filter(([, value]) => value.id === 'en')) as LanguagesList,
    [languagesList]
  )

  return filteredLanguagesList
}

export default useLanguageList
