import Drawer, { type DrawerProps } from 'antd/es/drawer'
import type { FC, ReactNode } from 'react'
import { memo, useRef } from 'react'
import styled, { useTheme } from 'styled-components'

import InformationBlockHeader from '@components/popups/informationBlock/InformationBlockHeader'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { useOutsideClick } from '@hooks/useOutsideClick/useOutsideClick'
import { textMediumRegularStyles } from '@styles/typography/text'
import { mediaQueries } from '@themes/mediaQueries'

type Props = {
  autoTestAttr?: string
  body: ReactNode
  close: () => void
  header: ReactNode
  isOpened: boolean
} & DrawerProps

const InformationBlock: FC<Props> = ({ autoTestAttr, body, close, header, isOpened, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(mediaQueries.mobile)
  const drawerRef = useRef<HTMLDivElement>(null)
  useOutsideClick({ elementsRefs: [drawerRef.current], handler: close, isActive: isOpened && !props.getContainer })

  return (
    <div ref={drawerRef}>
      <Drawer
        styles={{
          body: { display: 'grid', gridTemplateRows: 'auto 1fr', padding: 0 },
          wrapper: {
            width: '100%',
          },
        }}
        closable={false}
        destroyOnClose={true}
        getContainer={false}
        open={isOpened}
        placement="left"
        style={{ borderRadius: isMobile ? 0 : theme.borderRadius.default }}
        {...props}
      >
        <Content>
          <InformationBlockHeader autoTestAttr={autoTestAttr} close={close}>
            {header}
          </InformationBlockHeader>
          <Body>{body}</Body>
        </Content>
      </Drawer>
    </div>
  )
}

const Content = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
`

const Body = styled.main`
  ${textMediumRegularStyles};
  padding: 24px;
  overflow-y: auto;
  background: ${p => p.theme.gradient.grayToWhite}, ${p => p.theme.colors.backgroundMain};
  height: 100%;
`

export default memo(InformationBlock)
