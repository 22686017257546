import type { SimpleModals } from '@redux/features/modals/constants/simpleModals'

import type { RootState } from '../../store'

export const selectIsOpenedDrawerModal = (name?: SimpleModals) => (state: RootState) =>
  name && state.modals.drawerModals[name]
export const selectNotifications = (state: RootState) => state.modals.notifications
export const selectTildaPageId = (state: RootState) => state.modals.tildaPageId
export const selectIsBurgerMainMenu = (state: RootState) => state.modals.burgerMainMenu
export const selectDrawerModals = (state: RootState) => state.modals.drawerModals
