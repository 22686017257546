import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import type { LangCodesTypes } from '@reduxCommonApp/types/siteInfo'

import { FlagStyled } from '@base/FlagIcon'
import LangFlag from '@base/langFlag/LangFlag'
import Caption, { CaptionStyled } from '@typography/Caption'
import { getLanguageHref } from '@utils/language/languageHref'

type Props = {
  currentLangCode: LangCodesTypes
  langId: LangCodesTypes
  prefix: string
  title: string
}

const LangLabel: FC<Props> = ({ currentLangCode, langId, title }) => {
  const isCurrent = currentLangCode === langId

  return (
    <Wrapper $isCurrent={isCurrent} href={getLanguageHref(currentLangCode, langId)}>
      <Caption size="s">{title}</Caption>
      <LangFlag langId={langId} />
    </Wrapper>
  )
}

const Wrapper = styled.a<{ $isCurrent: boolean }>`
  display: flex;
  align-items: center;
  pointer-events: ${p => (p.$isCurrent ? 'none' : 'auto')};

  ${FlagStyled}, svg {
    margin-inline-start: 16px;
  }

  svg {
    fill: ${p => p.theme.colors.greyCarbon};
  }

  ${CaptionStyled} {
    color: ${({ $isCurrent, theme }) => ($isCurrent ? theme.colors.fontMain : theme.colors.fontSecondary)};
  }
`

export default memo(LangLabel)
