import styled from 'styled-components'

import { StyledDivider } from '@base/Divider'
import { StyledText } from '@typography/Text'

export const FooterWrapper = styled.footer`
  z-index: 1;
  background: ${p => p.theme.colors.footer};
  width: 100%;
  min-height: 387px;
  height: min-content;
  margin-top: 90px;

  ${p => p.theme.mediaQueries.mobileTablet} {
    margin-top: 32px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    display: grid;
    grid-template-areas: 'info' 'control' 'label';
    margin-top: 32px;

    ${StyledDivider} {
      display: none;
    }
  }
`

export const Label = styled.div`
  width: 100%;
  max-width: ${p => p.theme.sizes.contentMaxWidth}px;
  margin: 0 auto;
  padding-bottom: 48px;

  ${p => p.theme.mediaQueries.desktop} {
    padding: 0 20px 48px 20px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    text-align: center;
    padding: 20px 0;
    background: ${p => p.theme.gradient.grayToWhite};
    grid-area: label;

    ${StyledText} {
      opacity: 0.5;
    }
  }
`
