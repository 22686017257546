import type { LangCodesTypes } from '@reduxCommonApp/types/siteInfo'

import defaultParameters from '@constants/defaultParameters/defaultParameters'

export const getLanguageHref = (currentLangCode: LangCodesTypes, id: LangCodesTypes) => {
  const isCurrentDefaultLang = defaultParameters.langcode === currentLangCode
  const isNewDefaultLang = defaultParameters.langcode === id

  return isCurrentDefaultLang
    ? `/${id}${window.location.pathname}${window.location.search}`
    : isNewDefaultLang
    ? window.location.pathname.replace('/' + currentLangCode, '') + window.location.search
    : window.location.pathname.replace(currentLangCode, id) + window.location.search
}
