import styled from 'styled-components'

import Button from '@base/buttons/Button'
import { textMediumMediumStyles } from '@styles/typography/text'
import type { ThemeColorsType } from '@themes/themes'

export const TextButtonStyled = styled(Button)<{
  $color: ThemeColorsType
  $colorHover: ThemeColorsType
  $underline: boolean
}>`
  &.ant-btn {
    span {
      ${textMediumMediumStyles};
      text-transform: initial;
      color: ${p => p.theme.colors[p.$color]};
    }

    &:hover {
      span {
        color: ${p => (p.$underline ? 'inherit' : p.theme.colors[p.$colorHover])};
        text-decoration: ${p => (p.$underline ? 'underline' : 'none')};
      }
    }
  }
`
