import type { ReactElement } from 'react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectTheme } from '@reduxCommonApp/commonApp.selectors'
import { setTheme } from '@reduxCommonApp/commonApp.slice'

import { UilMoon, UilSun } from '@iconscout/react-unicons'
import { ThemeNames } from '@themes/themes'

export const themeIcons: Record<ThemeNames, ReactElement> = {
  [ThemeNames.DARK]: <UilSun />,
  [ThemeNames.LIGHT]: <UilMoon />,
}

const useSwitchTheme = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('common')
  const themeKey = useAppSelector(selectTheme)

  const switchThemeHandler = useCallback(() => {
    void dispatch(setTheme(themeKey === ThemeNames.LIGHT ? ThemeNames.DARK : ThemeNames.LIGHT))
  }, [dispatch, themeKey])

  const themeLabels = useMemo<Record<ThemeNames, string>>(
    () =>
      Object.fromEntries(Object.values(ThemeNames).map(themeName => [themeName, t(themeName)])) as Record<
        ThemeNames,
        string
      >,
    [t]
  )

  return {
    icon: themeIcons[themeKey],
    switchThemeHandler,
    themeLabel: themeLabels[themeKey],
  }
}

export default useSwitchTheme
