import { createSelector } from '@reduxjs/toolkit'

import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'

import type { RootState } from '../../store'

const selectCheckout = (state: RootState) => state.checkout

export const selectSearchForms = createSelector(selectCheckout, checkout => checkout.timetable.searchForms)
export const selectCurrentLeg = (state: RootState) => state.checkout.currentLeg
export const selectIsSecondLeg = (state: RootState) => state.checkout.currentLeg === 2
export const selectTotalLegs = (state: RootState) => state.checkout.totalLegs
export const selectTimetableSelectedData = createSelector(selectCheckout, checkout => checkout.timetable.selectedData)
export const selectCheapestTrain = (state: RootState) => state.checkout.timetable.cheapestTrainNumber
export const selectDepartureDate = (state: RootState) => state.checkout.timetable.departureDate
export const selectOriginalDepartureDate = (state: RootState) => state.checkout.timetable.originalDepartureDate
export const selectBooking = (state: RootState) => state.checkout.booking
export const selectBookingId = (state: RootState) => state.checkout.booking?.booking_id
export const selectPrebooking = (state: RootState) => state.checkout.prebooking
export const selectIsReadyToPay = (state: RootState) => state.checkout.payment.isReadyToPay
export const selectIsPaymentInProgress = (state: RootState) => state.checkout.payment.isPaymentInProgress
export const selectPostedPassengers = (state: RootState) => state.checkout.passengers.postedPassengersCount
export const selectCheckoutPassengersForm = (state: RootState) => state.checkout.passengers.passengersForm
export const selectCheckoutExtraServices = (state: RootState) => state.checkout.extraServices
export const selectCheckoutPaymentForm = (state: RootState) => state.checkout.payment.paymentForm
export const selectTimeTableSort = (state: RootState) => state.checkout.timetable.sort
export const selectHasLayover = createSelector(selectSearchForms, form => !!form[0][SearchFormKeys.layover])
export const selectQueryParams = (state: RootState) => state.checkout.queryParams
export const selectVoucherData = (state: RootState) => state.checkout.payment.voucher
export const selectSearchSessionId = (state: RootState) => state.checkout.searchSessionId
