import styled from 'styled-components'

import { captionSmallStyles } from '@styles/typography/caption'

export const Wrapper = styled.div<{ $isCurrent: boolean }>`
  ${captionSmallStyles};
  display: inline-flex;
  pointer-events: ${p => (p.$isCurrent ? 'none' : 'auto')};

  span {
    color: ${({ $isCurrent, theme }) => ($isCurrent ? theme.colors.fontMain : theme.colors.fontSecondary)};

    &:not(:first-child) {
      margin-inline-start: 10px;
    }
  }
`
