import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import { useGetServiceOrderStationsQuery } from '@redux/features/myTrips/myTrips.api'

import type { IMyTripsServiceOrder } from '@Types/myTrips/myTripsServiceOrder'
import Dates from '@components/myTrips/myTripsList/myTripsStoreOrder/myTripsServiceOrder/dates/Dates'
import Route from '@widgets/route/RouteInfo/components/RouteLegInfo/components/Route'

type Props = {
  serviceOrder: IMyTripsServiceOrder
  userId: UserIdType
}

const MyTripsServiceOrder: FC<Props> = ({
  serviceOrder: { arrival_datetime, arrival_station, departure_datetime, departure_station, id },
  userId,
}) => {
  const { data: stations } = useGetServiceOrderStationsQuery({ serviceOrderId: id, userId })
  const stationsArray = stations && Object.values(stations)

  return (
    <Wrapper>
      <Dates arrivalDateTime={arrival_datetime} departureDateTime={departure_datetime} />
      {stationsArray && (
        <Route arrivalStation={stations[arrival_station]} departureStation={stations[departure_station]} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export default memo(MyTripsServiceOrder)
