import type { BadgeProps } from 'antd/es/badge'
import dynamic from 'next/dynamic'
import type { FC, ReactElement } from 'react'
import { memo } from 'react'

import { selectIsLoggedIn } from '@redux/features/authorization/authorization.selectors'
import { useGetUpcomingStoreOrdersQuery } from '@redux/features/myTrips/myTrips.api'
import { useAppSelector } from '@redux/hooks'
import { skipToken } from '@reduxjs/toolkit/query/react'

import { useUserId } from '@hooks/user/useUserId/useUserId'

const CountBadgeHoc = dynamic(() => import('@hocs/badges/CountBadgeHoc'))

type Props = {
  children: ReactElement
} & BadgeProps

const TicketsBadgeHoc: FC<Props> = ({ children, ...props }) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const getUserId = useUserId()
  const { currentData: storeOrders } = useGetUpcomingStoreOrdersQuery((isLoggedIn && getUserId()) || skipToken)
  const count = storeOrders && Object.keys(storeOrders).length

  if (!count) return children

  return (
    <CountBadgeHoc count={count || ''} style={{ right: '-8px' }} {...props}>
      {children}
    </CountBadgeHoc>
  )
}

export default memo(TicketsBadgeHoc)
