import type { ButtonProps } from 'antd/es'
import type { FC } from 'react'

import type { ThemeColorsType } from '@themes/themes'

import { TextButtonStyled } from './styles'

export type TextButtonProps = ButtonProps & {
  color?: ThemeColorsType
  colorHover?: ThemeColorsType
  underline?: boolean
}

const TextButton: FC<TextButtonProps> = ({
  children,
  color = 'fontEmptyLabel',
  colorHover = 'primary',
  underline = false,
  ...props
}) => {
  return (
    <TextButtonStyled type="link" {...props} $color={color} $colorHover={colorHover} $underline={underline}>
      {children}
    </TextButtonStyled>
  )
}

export default TextButton
