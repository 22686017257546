import type { FC, ReactNode } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import Button from '@base/buttons/Button'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { UilMultiply } from '@iconscout/react-unicons'
import { mediaQueries } from '@themes/mediaQueries'
import Title from '@typography/Title'

type Props = {
  children?: ReactNode
  disabled?: boolean
  onClose?: () => void
  title: ReactNode
  withBorder?: boolean
}

const TitleWithClose: FC<Props> = ({ children, disabled, onClose, title, withBorder }) => {
  const isMobile = useMediaQuery(mediaQueries.mobile)

  return (
    <WrapperTitleWithClose withBorder={withBorder}>
      {children}
      <Title level={isMobile ? 5 : 4}>{title}</Title>
      {onClose && (
        <ButtonClose disabled={disabled} iconColor="fontSecondary" onClick={onClose} type="link">
          <UilMultiply />
        </ButtonClose>
      )}
    </WrapperTitleWithClose>
  )
}

export const WrapperTitleWithClose = styled.header<{ withBorder?: boolean }>`
  display: flex;
  align-items: center;
  padding: 22px 48px;
  position: relative;
  border-bottom: ${p => (p.withBorder ? `1px solid ${p.theme.colors.borderGrey}` : 'none')};

  button.ant-btn-link {
    svg,
    svg path {
      fill: ${p => p.theme.colors.fontSecondary};
    }

    &:hover {
      svg,
      svg path {
        fill: ${p => p.theme.colors.primary};
      }
    }
  }

  ${p => p.theme.mediaQueries.mobile} {
    padding: 18px 16px;
  }
`

export const ButtonClose = styled(Button)`
  &.ant-btn {
    margin-inline-start: auto;

    svg {
      fill: ${p => p.theme.colors.fontSecondary};
    }

    &:hover {
      svg {
        fill: ${p => p.theme.colors.primary};
      }
    }

    svg:first-child {
      margin-inline: 0;
    }

    ${p => p.theme.mediaQueries.mobile} {
      padding: 18px 18px 18px 24px;
    }
  }
`

export default memo(TitleWithClose)
