import type { FC } from 'react'

import type { LangCodesTypes } from '@reduxCommonApp/types/siteInfo'

import FlagIcon from '@base/FlagIcon'
import { UilGlobe } from '@iconscout/react-unicons'
import { flagByLanguageId } from '@utils/language/languageFlags'

type Props = {
  langId: LangCodesTypes
}

const LangFlag: FC<Props> = ({ langId }) => {
  return langId === 'en' ? <UilGlobe /> : <FlagIcon code={flagByLanguageId[langId] || langId} width="24px" />
}

export default LangFlag
