import { selectIsLoggedIn } from '@redux/features/authorization/authorization.selectors'
import { setIsOpenAuthorizationPopup } from '@redux/features/authorization/authorization.slice'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

const useOpenAuthPopup = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const dispatch = useAppDispatch()

  const openAuthPopup = () => dispatch(setIsOpenAuthorizationPopup(true))

  return { isLoggedIn, openAuthPopup }
}

export default useOpenAuthPopup
