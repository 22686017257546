import type { FC } from 'react'

import type { CurrencyType } from '@reduxCommonApp/types/commonAppState'

import { Wrapper } from '@components/header/RegionalMenu/regionalMenuDropdown/currency/CurrencyLabel/styled'

type Props = {
  isCurrent: boolean
} & CurrencyType

export const CurrencyLabel: FC<Props> = ({ isCurrent, label, sign }) => {
  return (
    <Wrapper $isCurrent={isCurrent}>
      <span>{label}</span>
      <span>{sign}</span>
    </Wrapper>
  )
}
