import { useGetCurrenciesQuery } from '@reduxCommonApp/commonApp.api'

import { getCurrencyFormat } from '@utils/currency/currencyFormat'

export const useCurrencyFormat = (price?: IPrice, separator?: string) => {
  const { data: currenciesList, isLoading } = useGetCurrenciesQuery(false)

  return price?.currency_code && typeof price?.number === 'number' && !isLoading
    ? getCurrencyFormat(currenciesList, price, separator)
    : ''
}
