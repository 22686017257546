import type { FC } from 'react'
import styled, { useTheme } from 'styled-components'

import Divider, { StyledDivider } from '@base/Divider'
import ControlButtonsFooter from '@components/Footer/control/controlButtonsFooter/ControlButtonsFooter'
import LogoWithTermsAndPolicy from '@components/Footer/logoWithTermsAndPolicy/LogoWithTermsAndPolicy'

const Control: FC = () => {
  const theme = useTheme()

  return (
    <Content>
      <LogoWithTermsAndPolicy />
      <Divider
        style={{
          borderColor: theme?.colors.fontSecondaryLight,
          height: '40px',
        }}
        dashed={false}
        type="vertical"
      />
      <ControlButtonsFooter />
    </Content>
  )
}

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  width: 100%;
  max-width: ${p => p.theme.sizes.contentMaxWidth}px;
  margin: 0 auto;
  padding: 48px 0;
  align-items: center;

  ${p => p.theme.mediaQueries.desktop} {
    grid-template-areas: 'controlButtonsFooter' 'termsAndPolicy';
    grid-template-columns: 1fr;
    padding: 40px 20px;
    grid-gap: 40px;

    ${StyledDivider} {
      display: none;
    }
  }

  ${p => p.theme.mediaQueries.mobile} {
    padding: 0 20px 20px 20px;
    grid-gap: 20px;
  }
`

export default Control
