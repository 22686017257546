import type { TooltipPlacement } from 'antd/es/tooltip'
import dynamic from 'next/dynamic'
import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import { useAppSelector } from '@redux/hooks'
import { selectIsMobileUserAgent } from '@reduxCommonApp/commonApp.selectors'

import { ThemeSwitcherWrapper } from '@components/ThemeSwitcher'
import { MobileMenuButtonStyled } from '@components/header/BurgerMenu/MobileMenuButton'
import MobileMenuButton from '@components/header/BurgerMenu/MobileMenuButton/MobileMenuButton'
import { RegionalMenuContainer } from '@components/header/RegionalMenu'
import { MyTripsPopupHOCContainer } from '@components/myTrips/MyTripsPopup'

const TabletDesktopButtons = dynamic(() => import('./components/TabletDesktopButtons'))

type Props = {
  isMobileMenuButton?: boolean
  placementRegionalMenu?: TooltipPlacement
  placementTicketsMenu?: TooltipPlacement
}

const ControlButtons: FC<Props> = ({ isMobileMenuButton = true, placementRegionalMenu, placementTicketsMenu }) => {
  const isMobileUserAgent = useAppSelector(selectIsMobileUserAgent)

  return (
    <ControlButtonsWrapper>
      {!isMobileUserAgent && (
        <TabletDesktopButtons
          placementRegionalMenu={placementRegionalMenu}
          placementTicketsMenu={placementTicketsMenu}
        />
      )}
      {/*<CartButton withCaption={true} />*/}
      {isMobileMenuButton && <MobileMenuButton />}
    </ControlButtonsWrapper>
  )
}

export const ControlButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  ${MobileMenuButtonStyled} {
    display: none;
  }

  ${p => p.theme.mediaQueries.desktopS} {
    ${MobileMenuButtonStyled}, ${MyTripsPopupHOCContainer} {
      display: flex;
    }
  }

  ${p => p.theme.mediaQueries.mobile} {
    ${MyTripsPopupHOCContainer}, ${RegionalMenuContainer}, ${ThemeSwitcherWrapper} {
      display: none;
    }
  }
`

export default memo(ControlButtons)
