import type { DropdownProps } from 'antd/es'
import { Dropdown as AntDropdown } from 'antd/es'
import type { FC } from 'react'
import styled from 'styled-components'

import type { ButtonProps } from '@base/buttons/Button'
import Button from '@base/buttons/Button'
import { UilAngleDown } from '@iconscout/react-unicons'

type Props = {
  buttonProps?: ButtonProps
  href?: string
  label: string
  withMenu: boolean
} & DropdownProps

const Dropdown: FC<Props> = ({ buttonProps, href, label, withMenu, ...props }) => {
  return (
    <AntDropdown {...props} align={{ overflow: { adjustX: true, adjustY: true } }}>
      <div>
        <ButtonStyled iconColor="fontSecondaryLight" type="link" {...buttonProps} href={href}>
          {label}
          {withMenu && <UilAngleDown />}
        </ButtonStyled>
      </div>
    </AntDropdown>
  )
}

const ButtonStyled = styled(Button)`
  height: 100%;
  white-space: break-spaces;

  &.ant-dropdown-open {
    & > span {
      color: ${p => p.theme.colors.primary};
    }

    & > svg {
      fill: ${p => p.theme.colors.icon};
    }
  }

  & > svg {
    margin-inline-start: 12px;
  }
`

export default Dropdown
