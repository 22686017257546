import type { CurrenciesList } from '@reduxCommonApp/types/commonAppState'

export const getCurrencyFormat = (currencies: CurrenciesList | undefined, price: IPrice, separator?: string) => {
  if (!(typeof price?.number === 'number')) return ''
  const sign = currencies && price.currency_code ? currencies[price.currency_code].sign : ''
  const locales = 'en-GB'
  const truncatedPrice = (separator || '') + (price.number ? Number(price.number).toLocaleString(locales) : '0')
  if (sign) {
    return `${sign}${truncatedPrice}`
  } else {
    return new Intl.NumberFormat(locales, {
      currency: price.currency_code,
      style: 'currency',
    })
      .format(Math.trunc(Number(price.number)))
      .replace(/\D00(?=\D*$)/, '')
  }
}
