import type { FC } from 'react'
import styled from 'styled-components'

import TermsAndPolicy from '@components/Footer/termsAndPolicy/TermsAndPolicy'
import SiteLogo from '@components/SiteLogo'
import GoHomeHoc, { GoHomeHocStyled } from '@hocs/links/GoHomeHoc'

const LogoWithTermsAndPolicy: FC = () => {
  return (
    <Container>
      <GoHomeHoc>
        <SiteLogo />
      </GoHomeHoc>
      <TermsAndPolicy />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 40px;
  position: relative;

  ${p => p.theme.mediaQueries.desktop} {
    grid-area: termsAndPolicy;

    ${GoHomeHocStyled} {
      display: none;
    }
  }

  ${p => p.theme.mediaQueries.mobile} {
    grid-template-columns: 100%;
    width: 100%;

    svg {
      display: none;
    }
  }
`

export default LogoWithTermsAndPolicy
